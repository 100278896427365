<template>
  <div class="vps-detail-backup">
    <!--    <base-loader v-if="isLoading" />-->
    <base-loader v-if="!serverId" />
    <div v-else>
      <page-block :title="$t('available.title')" class="vps-detail-backup__block">
        <main-card>
          <p class="standart-text info">
            <!--            {{ $tc('available.total', availableBackups) }}-->
            {{ $tc('available.total', availableBackups) }}
          </p>
          <p v-if="availableBackups" class="standart-text info">
            {{ $t('available.slots', { n: emptySlots }) }}
          </p>
          <p class="standart-text info"></p>
          <base-button slot="bodyEnd" :to="{ name: 'vpsConfig' }" nowrap>
            {{ $t('available.btn') }}
          </base-button>
        </main-card>
      </page-block>
      <page-block
        v-if="availableBackups > 0"
        :title="$t('created.title')"
        class="vps-detail-backup__block"
      >
        <plain-button
          v-if="emptySlots && listBackups.length"
          slot="action"
          color="primary"
          nowrap
          @click="addNewBackup"
        >
          {{ $t('created.btn') }}
        </plain-button>
        <base-empty
          v-if="!listBackups.length && availableBackups > 0"
          title=" "
          :text="'У Вас нет ни одной сохраненной копии. Защитите свои данные - создайте резервную копию вашего сервера'"
          class="vps-info__empty"
        >
          <base-button slot="link" @click="addNewBackup">
            {{ $t('created.btn') }}
          </base-button>
        </base-empty>
        <backup-banner
          v-if="listBackups.length"
          :list="listBackups"
          @remove="removeCurrentBackup($event)"
          @recover="recoverCurrentBackup($event)"
        />
      </page-block>
    </div>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import BackupBanner from '@/components/BackupBanner/BackupBanner.vue';
import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import updateServerInfo from '@/layouts/VPS/mixins/updateServerInfo';
import showErrorModal from '@/mixins/showErrorModal';
import createBackup from '@/mixins/backup';
import modals from '@/mixins/modals';
import storeMixin from '../../../mixins/index';
import DeleteBackup from '@/layouts/VPS/components/DeleteBackup';
import RecoverBackup from '@/layouts/VPS/components/RecoverBackup';
import Vue from 'vue';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
export default {
  name: 'VpsBackups',
  components: {
    BackupBanner,
    MainCard,
    BaseEmpty,
  },
  mixins: [showErrorModal, updateServerInfo, createBackup, storeMixin, modals],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof VpsTariff,
    },
  },
  data() {
    return {
      isLoadingNow: true,
    };
  },
  computed: {
    serverId() {
      return this.tariff.serverId;
    },
    stateServerIsStopped() {
      return this.tariff._serverInfo._state.key === 'stopped';
    },
    availableBackups() {
      return this.$store.getters['moduleVPS/getVpsCount'];
    },

    emptySlots() {
      return this.availableBackups > 0 ? this.availableBackups - this.occupiedSlots : 0;
    },
    listBackups() {
      return this.$store.getters['moduleVPS/moduleVpsOrder/listBackups'].filter(
        x => x.instanceUuid === this.serverId
      );
    },
    occupiedSlots() {
      return this.listBackups.length;
    },
  },
  mounted() {
    this.fetchVPS();
    this.fetchBackupsInfo();
    if (this.serverId) {
      this.setVpsInfo(this.serverId);
    } else {
      setTimeout(() => {
        this.setVpsInfo(this.serverId);
      }, 3000);
    }
    this.isLoadingNow = false;
  },
  methods: {
    fetchServerInfo() {
      this.$store.dispatch('moduleVPS/fetchServerInfo', { serverId: this.serverId });
    },
    fetchBackupsInfo() {
      this.$store.dispatch(
        'moduleVPS/moduleVpsOrder/setBackupsInfo',
        this.serverId
        // localStorage.getItem('serverId')
      );
    },
    addNewBackup() {
      // if (this.newFeatures && this.guestToolsStatus === 1) {
      this.createBackup(this.$t('new_backup'), {
        tariff: this.tariff,
      });
      // } else if (this.stateServerIsStopped)
      //   this.createBackup(this.$t('new_backup'), {
      //     tariff: this.tariff,
      //   });
      // else {
      //   this.showResModal(this.$t('server_state.error_backup'));
      // }
    },
    removeCurrentBackup(data) {
      return this.deleteBackup({ backup: data });
    },
    recoverCurrentBackup(data) {
      // if (this.newFeatures && this.guestToolsStatus === 1) {
      this.recoverBackup({ backup: data });
      // } else if (this.stateServerIsStopped) this.recoverBackup({ backup: data });
      // else {
      //   this.showResModal(this.$t('server_state.error_restore'));
      // }
    },
    deleteBackup(props = {}) {
      let self = null;
      this.$modals.open({
        name: 'DeleteCurrentBackup',
        size: 'big',
        component: DeleteBackup,
        closeOnBackdrop: false,
        props: props,
        title: this.$t('delete_backup'),
        on: {},
        onOpen: instance => (self = instance),
        onClose: () => {
          this.$store.getters['moduleVPS/moduleVpsOrder/listBackups'];
        },
      });
    },
    recoverBackup(props = {}) {
      //TODO DRY
      let self = null;
      this.$modals.open({
        name: 'RecoverCurrentBackup',
        size: 'medium',
        component: RecoverBackup,
        closeOnBackdrop: false,
        props: props,
        title: this.$t('recover_backup'),
        on: {},
        onOpen: instance => (self = instance),
        onClose: () => {
          this.$store.getters['moduleVPS/moduleVpsOrder/listBackups'];
        },
      });
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "server_state": {
        "error_backup" : "Бэкап можно сделать только на остановленном сервере!",
        "error_restore" : "Восстановление резервной копии возможно только на остановленном сервере!"
      },
      "available": {
        "title": "Доступно бэкапов",
        "total": "Вам недоступно создание резервных копий. | Вам доступно создание {count} резервной копии. | Вам доступно создание {count} резервных копий.",
        "slots": "Свободно слотов – {n}.",
        "text": "Бэкапы можно создавать вручную или настроить расписание",
        "btn": "Увеличить количество слотов"
      },
      "created": {
        "title": "Созданные бэкапы",
        "btn": "Новый бэкап +"
      },
      "schedule": {
        "title": "Расписание",
        "btn": "Новое расписание +"
      },
      "new_backup": "Создать резервную копию",
      "delete_backup": "Удалить резервную копию",
      "recover_backup": "Восстановить резервную копию"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.info {
  margin: 0.25rem 0;
}
  .vps-info__empty {
    margin-top: -4rem;
    +breakpoint(sm-and-up) {
    margin-top: -11rem;

    }
  }
</style>
